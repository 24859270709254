import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, List, ListItem, ListItemText, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { GlobalContext } from '../../../GlobalContext';
import { Context } from '../../table/overtime/Context';
import { applyingOvertime } from '../../../utils/Overtime';

const Reject = ({open, setOpen, data, checked}) => {
    const { t } = useTranslation()

    const {
        reloading,
    } = useContext(Context)

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [overtimes, setOvertimes] = useState([])

    const closing = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(open){
            console.log('data', data)
            console.log('checked', checked)

            setOvertimes(data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending'))
        }

    }, [checked, data, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle>
                    <Typography variant='body3'>
                        {t('overtime.table.modals.reject.title')}
                    </Typography>
                </DialogTitle>
                
                { data.filter(d => checked.includes(d.id)).some(d => d.status !== 'pending') && 
                    <DialogContent
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            borderBottom: 'none'
                        }}
                        dividers
                    >
                        <Grid container spacing={2}>
                            <Grid 
                                item 
                                xs={12}
                                sx={{
                                    height: '100%',
                                }}
                            >
                                <Alert
                                    severity="warning"
                                    sx={{
                                        width: '100%',
                                        opacity: 1,
                                    }}
                                    variant="filled"
                                >
                                    <AlertTitle>
                                        {t('overtime.table.modals.reject.alerts.already_managed.title')}
                                    </AlertTitle>

                                    {t('overtime.table.modals.reject.alerts.already_managed.message')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }

                <DialogContent dividers>
                    <Typography variant='body3'>
                        {t('overtime.table.modals.reject.message')}
                        
                        {overtimes.length !== 0 &&
                            <List>
                                { overtimes.map((row, index) => 
                                    <ListItem 
                                        key={index}
                                        secondaryAction={
                                            <>
                                                <TextField
                                                    label={t('overtime.table.rows.partial.title')}
                                                    value={row.amount}
                                                    disabled={true}
                                                    placeholder={t('overtime.table.rows.partial.placeholder')}
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment sx={{mr:-0.5}} position="end">
                                                                <WatchLaterIcon />
                                                            </InputAdornment>,
                                                    }}
                                                    size='small'
                                                    sx={{
                                                        width: '100px',
                                                    }}
                                                />
                                            </>
                                        }
                                    >
                                        <ListItemText primary={`${row.date} - ${row.full_name}`}/>
                                    </ListItem>
                                )}
                                
                            </List>
                        }
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            applyingOvertime(
                                setLoading,
                                overtimes,
                                closing,
                                reloading,
                                handlerAlert,
                            )
                        }}
                        color='success'
                        loading={loading}
                        disabled={data.filter(d => checked.includes(d.id)).filter(d => d.status === 'pending').length === 0}
                    >
                        {t('overtime.table.modals.reject.buttons.reject')}
                    </LoadingButton>

                    <Button
                        sx={{ mt: 0.5, mr: 2 }}
                        disableElevation
                        color='error'
                        variant='contained'
                        onClick={() => { closing() }}
                    >
                        {t('overtime.table.modals.reject.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    Reject
}