import { createContext, useContext, useState } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { getWeek } from "../../../utils/Dates";
import moment from "moment";
import { gettingSchedule, gettingTimecard } from "../../../utils/Overtime";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { gettingOpenPeriod } from "../../../utils/Timecard";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { handlerAlert } = useContext(GlobalContext)

    const apiRef = useGridApiRef();

    const [range1, setRange1] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [range2, setRange2] = useState([
        moment(getWeek()[0]),
        moment(getWeek()[1])
    ]);

    const [smartgroups, setSmartgroups] = useState([])
    const [smartgroup, setSmartgroup] = useState([])
    const [filter1, setFilter1] = useState('')

    const [employees, setEmployees] = useState([])
    const [employee, setEmployee] = useState([])
    const [filter2, setFilter2] = useState('')

    const [rows, setRows] = useState([])
    const [audit, setAudit] = useState([])
    const [search, setSearch] = useState('audit_date')

    const [periods, setPeriods] = useState([]);

    const [ready, setReady] = useState(false)
    const [renders, setRenders] = useState(0)

    const [loadingGettingSmartgroups, setLoadingGettingSmartgroups] = useState(false)
    const [loadingGettingEmployees, setLoadingGettingEmployees] = useState(false)
    const [loadingGettingTimecard, setLoadingGettingTimecard] = useState(false)
    const [loadingGettingSchedule, setLoadingGettingSchedule] = useState(false)
    const [loadingGettingAudit, setLoadingGettingAudit] = useState(false);
    const [loadingGettingOpenPeriod, setLoadingGettingOpenPeriod] = useState(false);

    const searching = async () => {
        await gettingTimecard(
            setLoadingGettingTimecard,
            range1,
            employee,
            setRows,
            handlerAlert,
        )

        await gettingOpenPeriod(
            setLoadingGettingOpenPeriod, 
            range1, 
            employee,
            smartgroup,
            'overtime',
            rows, 
            setPeriods, 
            handlerAlert
        )

        await gettingSchedule(
            setLoadingGettingSchedule,
            range1,
            employee,
            setRows,
            handlerAlert,
        )
    }

    const reloading = async () => {
        const data = JSON.parse(sessionStorage.getItem('overtime'))

        await gettingTimecard(
            setLoadingGettingTimecard,
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            setRows,
            handlerAlert,
        )

        await gettingOpenPeriod(
            setLoadingGettingOpenPeriod, 
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            data?.reloading?.smartgroups,
            'overtime',
            rows, 
            setPeriods, 
            handlerAlert
        )

        await gettingSchedule(
            setLoadingGettingSchedule,
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            setRows,
            handlerAlert,
        )
    }

    return(
        <Context.Provider 
            value={{
                range1, setRange1,
                range2, setRange2,
                periods, setPeriods,
                smartgroups, setSmartgroups,
                smartgroup, setSmartgroup,
                filter1, setFilter1,
                employees, setEmployees,
                employee, setEmployee,
                filter2, setFilter2,
                rows, setRows,
                audit, setAudit,
                search, setSearch,
                ready, setReady,
                renders, setRenders,
                loadingGettingSmartgroups, setLoadingGettingSmartgroups,
                loadingGettingEmployees, setLoadingGettingEmployees,
                loadingGettingTimecard, setLoadingGettingTimecard,
                loadingGettingSchedule, setLoadingGettingSchedule,
                loadingGettingAudit, setLoadingGettingAudit,
                loadingGettingOpenPeriod, setLoadingGettingOpenPeriod,
                apiRef,
                searching,
                reloading,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export {
    Context,
    ContextProvider
}