import moment from "moment"
import { instance } from "./session/axios"

const gettingTimecard = async (
    setLoading,
    range,
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).overtime.get_timecard){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))],
        }

        instance.post('timecard/multi_read', body)
        .then(response => {
            const rows = []

            for(let person_number of Object.keys(response.data.daily_totals)){
                for(let date of Object.keys(response.data.daily_totals[person_number])){
                    if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR']){
                        rows.push({
                            id: `${person_number}-${date}-pending`,
                            person_number: person_number,
                            full_name: employees.find(employee => employee.id === person_number)?.full_name,
                            date: date,
                            shifts: [],
                            punches: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date] : [],
                            action: '',
                            comment: undefined,
                            notes: '',
                            partial_amount: '00:00',
                            amount: moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm'),
                            status: 'pending',
                        })
                    }

                    if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']){
                        let amount = moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm')

                        if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']){
                            const time_approved = moment.duration(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS']?.amount_in_time).asMinutes()
                            amount = moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']?.amount_in_time, ['HH:mm', 'H:mm']).add(time_approved, 'minutes').format('HH:mm')
                        }

                        rows.push({
                            id: `${person_number}-${date}-rejected`,
                            person_number: person_number,
                            full_name: employees.find(employee => employee.id === person_number)?.full_name,
                            date: date,
                            shifts: [],
                            punches: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date] : [],
                            action: response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS'] ? 'some' : 'none',
                            comment: undefined,
                            notes: '',
                            partial_amount: moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm'),
                            amount: amount,
                            status: 'rejected',
                        })
                    }

                    if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS']){
                        let amount = moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm')
                        
                        if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']){
                            const time_rejected = moment.duration(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS']?.amount_in_time,  ['HH:mm', 'H:mm']).asMinutes()
                            amount = moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS']?.amount_in_time, ['HH:mm', 'H:mm']).add(time_rejected, 'minutes').format('HH:mm')
                        }

                        rows.push({
                            id: `${person_number}-${date}-approved`,
                            person_number: person_number,
                            full_name: employees.find(employee => employee.id === person_number)?.full_name,
                            date: date,
                            shifts: [],
                            punches: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date] : [],
                            action: response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS RECHAZADAS'] ? 'some' : 'all',
                            comment: undefined,
                            notes: '',
                            partial_amount: moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['EM HORAS APROBADAS']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm'),
                            amount: amount,
                            status: 'approved',
                        })
                    }
                }
            }
            
            setRows(rows)
        })
        .catch(error => { 
            handlerAlert(
                'overtime.table.alerts.error_get_timecard.title', 
                'overtime.table.alerts.error_get_timecard.message',
                'error',
            )

            return []
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'overtime.table.alerts.get_timecard.title', 
            'overtime.table.alerts.get_timecard.message',
            'error',
        )
    }
}

const gettingSchedule = async (
    setLoading,
    range,
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).overtime.get_schedule){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))],
        }

        await instance.post('schedule/multi_read', body)
        .then(response => {
            setRows(prev => {
                for(const row of prev){
                    row.shifts = response.data?.[row.person_number]?.['shifts']?.[row.date] ? response.data?.[row.person_number]?.['shifts']?.[row.date] : []
                }
                
                return prev
            })
            
        })
        .catch(error => { 
            handlerAlert(
                'overtime.table.alerts.error_get_schedule.title', 
                'overtime.table.alerts.error_get_schedule.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'overtime.table.alerts.get_schedule.title', 
            'overtime.table.alerts.get_schedule.message',
            'error',
        )
    }
}

const applyingOvertime = async (
    setLoading,
    overtimes,
    closing,
    reloading,
    handlerAlert,
) => {
    setLoading(true)
    
    if(JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime || JSON.parse(sessionStorage.getItem('privileges')).overtime.remove_approve_overtime){
        await instance.post('overtime/approve', {
            overtimes: overtimes.map(overtime => ({
                person_number: overtime.person_number,
                date: overtime.date,
                action: overtime.action,
                amount:  moment.duration(overtime.partial_amount).asHours(),
                comment: undefined,
                notes: undefined,
            }))
        })
        .then(response => {
            closing()
            reloading()
        })
        .catch(error => {
            handlerAlert(
                'overtime.table.alerts.error_managed_overtime.title', 
                'overtime.table.alerts.error_managed_overtime.message',
                'error',
            )
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'overtime.table.alerts.manage_overtime.title', 
            'overtime.table.alerts.manage_overtime.message',
            'error',
        )
    }
}

export {
    gettingTimecard,
    gettingSchedule,
    applyingOvertime,
}