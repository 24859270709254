import { Box, Chip, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"
import { memo, useContext, useEffect, useState } from "react"
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useTranslation } from "react-i18next";
import { Context } from "./Context";
import moment from "moment";

const ShiftCell = memo(({params}) => {
    return (
        <Box>
            { params?.value?.map((shift, index) =>
                <Chip
                    key={index}
                    sx={{width: '150px'}}
                    color={'info'}
                    icon={<WorkHistoryIcon sx={{p: 0.25}}/>} 
                    label={
                        <Box sx={{width: '80px', textAlign: 'left', ml: 2}}>
                            {`${moment(shift[0].start, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}-${moment(shift[shift.length - 1].end, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}`}
                        </Box>
                    }
                />
            )}
        </Box>
    )
})

const PunchCell = memo(({params}) => {
    const { 
        periods,
    } = useContext(Context)

    const totalSpan = [...new Set(params.value.map(punch => punch.total_span_index))]

    return (
        totalSpan.map((total_index, index) => 
            <Box key={`${total_index}-${index}`}>
                <center>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        { params.value.filter(punch => punch.total_span_index === total_index).map((punch, index) => 
                            <Box key={index}>
                                { punch.type === 'in' &&
                                    <Chip
                                        sx={{width: '120px'}}
                                        color={'success'}
                                        icon={<AssignmentIndIcon sx={{p: 0.25}}/>} 
                                        label={<Box sx={{width: '80px', textAlign: 'left', ml: 2}}>{punch?.time}</Box>}
                                        disabled={moment(periods.find(period => period?.person_number === params?.row?.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(params?.row?.date, 'YYYY-MM-DD'))}
                                    />
                                }

                                { punch.type === 'out' &&
                                    <Chip
                                        sx={{width: '120px'}}
                                        color={'error'}
                                        icon={<AssignmentIndIcon sx={{p: 0.25}}/>} 
                                        label={<Box sx={{width: '80px', textAlign: 'left', ml: 2}}>{punch?.time}</Box>}
                                        disabled={moment(periods.find(period => period?.person_number === params?.row?.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(params?.row?.date, 'YYYY-MM-DD'))}
                                    />
                                }
                            </Box>
                        )}
                    </Stack>
                </center>
            </Box>
        )
    )
})

const ActionCell = memo(({params}) => {
    const { t } = useTranslation()
   
    const { 
        apiRef,
        rows,
        periods,
    } = useContext(Context)

    const [action, setAction] = useState(rows.find(row => row.id === params.row.id)?.action)

    useEffect(() => {
        setAction(rows.find(row => row.id === params.row.id)?.action)
    }, [params, rows])
    
    return(
        <>
            <FormControl fullWidth>
                <InputLabel id="laction">{t('overtime.table.rows.action.title')}</InputLabel>
                <Select
                    labelId="laction"
                    id="action"
                    value={action}
                    label={t('overtime.table.rows.action.title')}
                    onChange={(event) => { 
                        setAction(event.target.value)
                        
                        let nrows = rows

                        for(const row of nrows){
                            if(row.id === params.row.id){
                                row.action = event.target.value
                                if(event.target.value === 'all' || event.target.value === 'some' || event.target.value === 'none'){
                                    row.partial_amount = row.amount
                                }else{
                                    row.partial_amount = '00:00'
                                }
                            }
                        }

                        //console.log('nrows', nrows)

                        apiRef.current.setRows(nrows)
                    }}
                    disabled={params.row.status !== 'pending' || moment(periods.find(period => period?.person_number === params?.row?.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(params?.row?.date, 'YYYY-MM-DD'))}
                >
                    <MenuItem value={''}>{t('overtime.table.rows.action.no_action')}</MenuItem>
                    <MenuItem value={'all'}>{t('overtime.table.rows.action.full_approval')}</MenuItem>
                    <MenuItem value={'some'}>{t('overtime.table.rows.action.partial_approval')}</MenuItem>
                    <MenuItem value={'none'}>{t('overtime.table.rows.action.reject_everything')}</MenuItem>
                </Select>
            </FormControl>
        </>
    )
})

const PartialCell = memo(({params}) => {
    const { t } = useTranslation()
   
    const { 
        rows,
        setRows,
        periods,
    } = useContext(Context)

    const [partial, setPartial] = useState(rows.find(row => row.id === params.row.id)?.partial_amount)

    useEffect(() => {
        setPartial(rows.find(row => row.id === params.row.id)?.partial_amount)
    }, [params, rows])

    return(
        <>
            <TextField
                label={t('overtime.table.rows.partial.title')}
                value={partial}
                onChange={(event) => {
                    setPartial(event.target.value)

                    setRows(prev => {
                        for(const row of prev){
                            if(row.id === params.row.id){
                                row.partial_amount = event.target.value
                            }
                        }

                        return prev
                    })
                }}
                disabled={params.row.action !== 'some' || params.row.status !== 'pending' || moment(periods.find(period => period?.person_number === params?.row?.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(params?.row?.date, 'YYYY-MM-DD'))}
                placeholder={t('overtime.table.rows.partial.placeholder')}
                error={!/^\d{1,2}:[0-5]\d$/.test(partial)}
                helperText={!/^\d{1,2}:[0-5]\d$/.test(partial) ? t('overtime.table.rows.partial.error') : ""}
                InputProps={{
                    endAdornment: 
                        <InputAdornment position="end">
                            <WatchLaterIcon />
                        </InputAdornment>,
                }}
            />
        </>
    )
})

const Cell = ({params}) => {
    switch(params?.field){
        case 'shifts':
            return <ShiftCell params={params}/>
        case 'punches':
            return <PunchCell params={params}/>
        case 'action':
            return <ActionCell params={params}/>
        case 'parcial_amount':
            return <PartialCell params={params}/>
        default:
    }
}

const renderCell = (params) => {
    return (
        <>
            <Box 
                id={`${params?.field}-cell-${params?.row?.id}`}
                sx={{
                    display: 'grid', 
                    placeItems: 'center',
                    width: '100%',
                    height: '100%',
                    gap: '10px',
                    p: 1.5
                }}
            >
                <Cell params={params}/>
            </Box>
        </>
    )
}

export { renderCell }