import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deletingDayPaycode, deletingHourPaycode, delettingShift } from '../../../utils/Schedule'
import { GlobalContext } from '../../../GlobalContext'

const DeletionConfirmation = ({open, setOpen, type, dataDelete, setDataDelete, dataPaycodeDay, setDataPaycodeDay, dataPaycodeHour, setDataPaycodeHour, reloading}) => {
    const { t } = useTranslation()
    
    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
        dataDelete.setDisabled(false)
        dataPaycodeDay.setDisabled(false)
        dataPaycodeHour.setDisabled(false)

        setDataDelete({
            shift: null,
            person_number: null, 
            date: null, 
            setDisabled: () => {}
        })
        setDataPaycodeDay({
            day: null,
            setDisabled: () => {}
        })
        setDataPaycodeHour({
            hour: null,
            setDisabled: () => {}
        })
    }

    useEffect(() => {
        if(open){
            console.log('dataDelete', dataDelete)
        }
    }, [open, dataDelete])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.deletion_confirmation.title') }</Typography>
                </DialogTitle>

                <DialogContent 
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.deletion_confirmation.message') }</Typography>
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <LoadingButton 
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            if(type === 'shift'){
                                delettingShift(
                                    setLoading, 
                                    dataDelete.person_number, 
                                    dataDelete.date, 
                                    dataDelete.shift, 
                                    closing,
                                    reloading,
                                    handlerAlert
                                )
                            }
                            
                            if(type === 'PCH'){
                                deletingHourPaycode(
                                    setLoading, 
                                    dataPaycodeHour.hour, 
                                    closing, 
                                    reloading, 
                                    handlerAlert
                                )
                            }

                            if(type === 'PCD'){
                                deletingDayPaycode(
                                    setLoading, 
                                    dataPaycodeDay.day, 
                                    closing,
                                    reloading, 
                                    handlerAlert
                                )
                            }
                        }} 
                        loading={loading}
                    >
                        {t('schedule.table.modals.buttons.delete')}
                    </LoadingButton>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('schedule.table.modals.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    DeletionConfirmation
} 