import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, gridClasses } from "@mui/x-data-grid-pro";
import { FilterC } from "../../filter/FilterComon";
import { Avatar, Box, Collapse, LinearProgress } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { daysBetween } from "../../../utils/Dates";
import { Context } from "./Context";
import { renderCell } from "./RenderCell";
import { getBorder, stringToColor } from "../../../utils/Styles";
import RefreshIcon from '@mui/icons-material/Refresh';
//import SaveIcon from '@mui/icons-material/Save';
import WalletIcon from '@mui/icons-material/Wallet';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import GroupsIcon from '@mui/icons-material/Groups'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { ContextMenu } from "../../context_menu/ContextMenu";
import { AddPaycode } from "../../dialog/schedule/AddPaycode";
import { AddShift } from "../../dialog/schedule/AddShift";
import CleanSchedule from "../../dialog/schedule/CleanSchedule";
import { useNavigate } from "react-router-dom";
import { Table as Audit } from '../audit/Table'
import { LoadShifts } from "../../dialog/schedule/LoadShifts";
import { DeletionConfirmation } from "../../dialog/schedule/DeletionConfirmation";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { getGridStringOperators, GridColumnMenu } from "@mui/x-data-grid";

const CustomColumnMenu = (props) => {
    return (
        <>
            <GridColumnMenu
                {...props}
                slots={{
                    // Hide `columnMenuColumnsItem`
                    columnMenuColumnsItem: null,
                }}
            />
        </>
    )
}

const Table = () => {
    const { t } =  useTranslation()
    const {
        apiRef,
        loadingGettingOpenPeriod,
        loadingGettingSchedule,
        range1,
        rangeColumns,
        rows,
        smartgroup,
        employee,
        reloading,
        deleteOpen,
        setDeleteOpen,
        dataDelete,
        setDataDelete,
        dataPaycodeDay, 
        setDataPaycodeDay,
        dataPaycodeHour, 
        setDataPaycodeHour,
        type,
        periods,
    } = useContext(Context)

    const navigate = useNavigate()

    const [cell, setCell] = useState({ column: null, row: null })
    const [checked, setChecked] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const [addOpenPaycodeHour, setAddOpenPaycodeHour] = useState(false)
    const [addOpenShift, setAddOpenShift] = useState(false)
    const [AddLoadOpen, setAddLoadOpen] = useState(false)
    const [cleanOpen, setCleanOpen] = useState(false)

    const columns = useMemo(() => [
        {
            field: 'avatar',
            headerName: t('schedule.table.columns.avatar'),
            alignHeader: 'center',
            width: 60,
            minWidth: 60,
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            headerAlign: 'center',
            filterOperators: getGridStringOperators().filter(operator => operator.value !== 'isAnyOf'),
            renderCell: (params) => {
                return (
                    <Box sx={{pt: 1, pb: 1}}>
                        <Avatar sx={{ bgcolor: stringToColor(params.row.full_name) }}>
                            {params.row.full_name.charAt(0)}
                        </Avatar>
                    </Box>
                )
            }
        },
        { 
            field: 'full_name', 
            headerName: t('schedule.table.columns.full_name'),
            flex: 2,
            minWidth: 250,
            filterOperators: getGridStringOperators().filter(operator => operator.value !== 'isAnyOf'),
        },
        { 
            field: 'person_number', 
            headerName: t('schedule.table.columns.person_number'), 
            flex: 0.5,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter(operator => operator.value !== 'isAnyOf'),
        },
        ...daysBetween(rangeColumns[0], rangeColumns[1]).map(day => ({
            field: day, 
            headerName: day, 
            flex: 1,
            minWidth: 200,
            disableColumnMenu: true,
            sortable: false,
            headerAlign: 'center',
            renderCell,
        })),
        { 
            field: 'planned_hours', 
            headerName: t('schedule.table.columns.planned_hours'), 
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            width: 150,
        }
    ], [t, rangeColumns])

    const options = useMemo(() => [
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.get_paycodes && JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_hours,
            name: t('schedule.table.menu.add_paycode'),
            click: () => {
                setAddOpenPaycodeHour(true)
                setContextMenu(null)
            },
            icon: <WalletIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: <AddPaycode
                data={rows.map(row => ({ id: row.id, name: row.full_name }))}
                open={addOpenPaycodeHour} 
                setOpen={setAddOpenPaycodeHour} 
                checked={rows.filter((row) => checked.includes(row.id)).map((row) => ({ id: row.id, name: row.full_name }))} 
                cell={cell}
                setCell={setCell}
                reloading={reloading}
            />
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.add_shift,
            name: t('schedule.table.menu.add_shift'),
            click: () => {
                setAddOpenShift(true)
                setContextMenu(null)
            },
            icon: <WorkHistoryIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: <AddShift
                data={rows.map(row => ({ id: row.id, name: row.full_name }))}
                open={addOpenShift} 
                setOpen={setAddOpenShift}
                checked={rows.filter((row) => checked.includes(row.id)).map((row) => ({ id: row.id, name: row.full_name }))}
                cell={cell}
                setCell={setCell}
                reloading={reloading}
            />
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.add_shift,
            name: t('schedule.table.menu.load_shifts'),
            click: () => {
                setAddLoadOpen(true)
                setContextMenu(null)
            },
            icon: <WorkHistoryIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: <LoadShifts
                open={AddLoadOpen} 
                setOpen={setAddLoadOpen}
                reloading={reloading}
            />
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.clean_schedule,
            name: t('schedule.table.menu.clean_row'),
            click: () => {
                setCleanOpen(true)
                setContextMenu(null)
            },
            icon: <CleaningServicesIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: <CleanSchedule 
                data={rows.map(row => ({ id: row.id, name: row.full_name }))}
                open={cleanOpen} 
                setOpen={setCleanOpen}
                checked={rows.filter((row) => checked.includes(row.id)).map((row) => ({ id: row.id, name: row.full_name }))} 
                cell={cell}
                setCell={setCell}
                reloading={reloading}
            />
        },
        /* {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page,
            name: t('Save environment'),
            click: () => { 
                sessionStorage.setItem('schedule', JSON.stringify({
                    environment: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)),
                        employees: [...new Set(employee)],
                    },
                    redirect: null,
                }))
            },
            icon: <SaveIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page || JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        }, */
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).employees.show_page,
            name: t('schedule.table.menu.go_to_employees'),
            click: () => { 
                // em: {id, full_name, smarthgroup}
                // sg: {id, name}
                sessionStorage.setItem('employees', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('employees'))?.environment ? JSON.parse(sessionStorage.getItem('employees')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: employee.filter(e => checked.includes(e.id)).map(e => e),
                    },
                }))

                navigate('/employees')
            },
            icon: <GroupsIcon />,
            divider: true,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t(open ? 'employees.employee_table.menu.close_audit' : 'employees.employee_table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpen(!open)
            },
            icon: <PendingActionsIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page || JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('employees.employee_table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            divider: true,
            disabled: JSON.parse(sessionStorage.getItem('schedule'))?.reloading ? false : true,
            seleted: false,
            onlyOne: false
        }
    ], [t, rows, addOpenPaycodeHour, cell, reloading, addOpenShift, AddLoadOpen, cleanOpen, checked, open, range1, smartgroup, employee, navigate])

    return (
        <Box
            sx={{
                [`.${gridClasses.cell}.open`]: { backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'black' : 'transparent' },
                [`.${gridClasses.cell}.close`]: { backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[900] : grey[200] },
            }}
        >
            <div
                onContextMenu={(event) => {
                    event.preventDefault();

                    if(addOpenShift || addOpenPaycodeHour || cleanOpen || AddLoadOpen){
                        return
                    }

                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >
                <DataGridPro 
                    apiRef={apiRef}
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    //unstable_headerFilters
                    checkboxSelection
                    loading={loadingGettingSchedule || loadingGettingOpenPeriod}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: useCallback(() => <FilterC view={'schedule'} />, []),
                        loadingOverlay: LinearProgress,
                        columnMenu: CustomColumnMenu,
                    }}
                    slotProps={{
                        row: {
                            onContextMenu: (e) => {
                                if( e?.target?.dataset?.field){
                                    setCell({
                                        column: e?.target?.dataset?.field,
                                        row: e?.currentTarget?.getAttribute('data-id')
                                    })
                                }else{
                                    const date = e?.target?.id.match(/\d{4}-\d{2}-\d{2}/)

                                    setCell({
                                        column: date ? date[0] : null,
                                        row: e?.currentTarget?.getAttribute('data-id')
                                    })
                                }
                            },
                        },
                    }}
                    rowSelectionModel={checked}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setChecked(newRowSelectionModel);
                    }}
                    sx={{
                        height: `calc(100vh - ${open ? '55vh' : '120px'})`,
                    }}
                    getRowHeight={(params) => { 
                        const { model } = params

                        let length = 64

                        for (const key in model) {
                            let counter = 0

                            if(model[key].shifts){
                                counter = counter + model[key].shifts.length * 47
                            }

                            if(model[key].hours){
                                counter = counter + model[key].hours.length * 47
                            }

                            if(model[key].days){
                                counter = counter + model[key].days.length * 47
                            }

                            if(counter > length){
                                length = counter
                            }
                        }

                        return length
                    }}
                    pinnedColumns={{
                        left: [
                            GRID_CHECKBOX_SELECTION_COL_DEF.field,
                            'avatar', 
                            'full_name', 
                            'person_number'
                        ]
                    }}
                    getCellClassName={(params) => {
                        if(!moment(params?.field, 'YYYY-MM-DD').isValid()){
                            return ''
                        }
        
                        const style = moment(periods.find(period => period.person_number === params?.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(params?.field, 'YYYY-MM-DD'))
        
                        return  style ? 'close' : 'open'
                    }}
                    hideFooter
                    disableRowSelectionOnClick
                    columnBuffer={5}
                    rowBuffer={5}
                />

                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={checked}
                />

                <DeletionConfirmation 
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    type={type}
                    dataDelete={dataDelete} 
                    setDataDelete={setDataDelete}
                    dataPaycodeDay={dataPaycodeDay}
                    setDataPaycodeDay={setDataPaycodeDay}
                    dataPaycodeHour={dataPaycodeHour}
                    setDataPaycodeHour={setDataPaycodeHour}
                    reloading={reloading}
                />
            </div>

            <Collapse 
                sx={{
                    height: '55vh', 
                    mt: 2.5,
                    ml: -2.5, 
                    mr: -2.5,
                    p: 2.5,
                    borderTop: (theme) => getBorder (theme.palette.mode),
                }} 
                in={open} 
                timeout="auto" 
                unmountOnExit
            >   
                <Audit 
                    open={open}
                    setOpen={setOpen}
                    view={'schedule'}
                />
            </Collapse>
        </Box>
    )
}

export { 
    Table
} 